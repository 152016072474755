import { useMutation } from '@apollo/client';
import { LOGIN } from 'graphql/auth';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const LoginForm = ({ hasLabel }) => {
  const signIn = useSignIn();
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: data => {
      if (
        signIn({
          auth: {
            token: data.login.jwt
          },
          userState: data.login.user
        })
      ) {
        toast.success(`Welcome ${data.login.user.username}`);
        navigate(0);
      } else {
        console.error('Something went wrong');
      }
    },
    onError: error => toast.error(error.message)
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    login({
      variables: {
        input: { identifier: formData.email, password: formData.password }
      }
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0">Remember Me</Form.Check.Label>
          </Form.Check>
        </Col>

        {/* <Col xs="auto">
          <Link
            className="mb-0 fs--1"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forget Password?
          </Link>
        </Col> */}
      </Row>

      <Form.Group>
        <Button
          loading={loading}
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || loading}
        >
          Log in
        </Button>
      </Form.Group>
      {/*
      <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons /> */}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
