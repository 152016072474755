import { useMutation, useQuery } from '@apollo/client';
import DeceasedRecord from 'components/dashboard/DeceasedRecord';
import { RecordForm } from 'components/dashboard/RecordForm';
import dayjs from 'dayjs';
import { CREATE_DECEASED_RECORD, DECEASED_RECORDS } from 'graphql/dashboard';
import React, { useMemo, useState } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const [key, setKey] = useState('deceased-record');
  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  let uploadError = false;

  const { data: deceasedRecordsData } = useQuery(DECEASED_RECORDS, {
    skip: !useIsAuthenticated(),
    onError: error => toast.error(error.message)
  });

  const deceasedRecords = useMemo(
    () =>
      deceasedRecordsData?.deceasedRecords?.data?.map(record => ({
        id: record.id,
        name: record.attributes.fullName,
        phone: record.attributes.phone,
        deathDate: dayjs(record.attributes.deathDate).format('DD/MM/YYYY'),
        dateOfBirth: dayjs(record.attributes.dateOfBirth).format('DD/MM/YYYY'),
        gender: record.attributes.gender,
        address: record.attributes.address,
        plotNumber: record.attributes.cemeteryPlotInformation.plotNumber,
        billings: record.attributes.billings.data,
        files: [
          ...record.attributes.deceasedsDocuments.data,
          ...record.attributes.billings.data?.flatMap(
            billing => billing.attributes.documents.data
          )
        ]
      })) ?? [],
    [deceasedRecordsData]
  );

  const [createDeceasedRecord, { loading }] = useMutation(
    CREATE_DECEASED_RECORD,
    {
      onCompleted: data => {
        toast.success('Deceased record created successfully!');
        navigate(`/edit/${data.createDeceasedRecord.data.id}`);
      },
      onError: error => toast.error(error.message)
    }
  );

  const onSubmit = async data => {
    let formData = {};

    if (data.progress.step1) {
      formData = {
        publishedAt: dayjs(),
        additionalInformation: data.additionalInformation,
        address: data.address,
        age: data.age,
        phone: data.phone,
        deathDate: dayjs(data.deathDate).format('YYYY-MM-DD'),
        dateOfBirth: dayjs(data.dateOfBirth).format('YYYY-MM-DD'),
        fullName: data.fullName,
        gender: data.gender,
        nextOfKeen: data.nextOfKeen.map(keen => ({
          fullName: keen.fullName,
          relationship: keen.relationship,
          contactNumber: keen.contactNumber
        }))
      };
    }

    if (data.progress.step2) {
      let uploads = [];
      if (data?.deceasedsDocuments) {
        for (const file of data.deceasedsDocuments) {
          const uploadFormData = new FormData();
          uploadFormData.append('files', file.file);
          try {
            const response = await fetch(process.env.REACT_APP_API_URL_UPLOAD, {
              method: 'POST',
              body: uploadFormData,
              headers: {
                Authorization: authHeader
              }
            });

            if (!response.ok) {
              uploadError = true;
              console.error('Error uploading image:');
            } else {
              const data = await response.json();
              uploads.push(data?.[0]?.id);
            }
          } catch (error) {
            uploadError = true;
            console.error('Error uploading image:', error);
          }
        }
      }
      formData = {
        ...formData,
        cemeteryPlotInformation: {
          additionalInformation:
            data.cemeteryPlotInformation.additionalInformation,
          cemeteryLocation: data.cemeteryPlotInformation.cemeteryLocation,
          plotNumber: Number(data.cemeteryPlotInformation.plotNumber)
        },
        deceasedsDocuments: uploads
      };
    }

    if (!uploadError) {
      await createDeceasedRecord({
        variables: {
          data: formData
        }
      }).then(res => {
        console.log(res);
      });
    } else {
      toast.error('File upload failed, Try again');
    }
  };

  return (
    <Tabs activeKey={key} onSelect={k => setKey(k)}>
      <Tab eventKey="deceased-record" title="Deceased Record" className="pt-3">
        <DeceasedRecord setKey={setKey} records={deceasedRecords} />
      </Tab>
      <Tab eventKey="add-record" title="Add Record" className="pt-3">
        <RecordForm onSubmit={onSubmit} loading={loading} />
      </Tab>
    </Tabs>
  );
};

export default Dashboard;
