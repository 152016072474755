import { useMutation, useQuery } from '@apollo/client';
import { UserForm } from 'components/users/UserForm';
import { GET_USER_BY_ID, UPDATE_USER } from 'graphql/users';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UserEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: userData } = useQuery(GET_USER_BY_ID, {
    skip: !id,
    variables: {
      usersPermissionsUserId: id
    },
    onError: error => toast.error(error.message)
  });

  const user = userData?.usersPermissionsUser?.data ?? {};

  const [editUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      toast.success('User created successfully!');
      navigate(-1);
    },
    onError: error => toast.error(error.message, { variant: 'error' }),
    refetchQueries: ['Users']
  });

  const onSubmit = async data => {
    let formData = {};
    if (data.password) {
      formData = {
        username: data.username,
        password: data.password,
        email: data.email,
        blocked: !data.blocked
      };
    } else {
      formData = {
        username: data.username,
        email: data.email,
        blocked: !data.blocked
      };
    }
    await editUser({
      variables: {
        updateUsersPermissionsUserId: id,
        data: formData
      }
    });
  };

  return <UserForm onSubmit={onSubmit} loading={loading} initialData={user} />;
};
