export const paths = {
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    checkEmail: '/check-email',
    resetPassword: '/reset-password'
  },
  dashboard: {
    root: '/dashboard',
    users: '/users'
  }
};

export const AdminRoutes = [
  {
    label: 'Dashboard',
    labelDisable: true,
    children: [
      {
        name: 'Dashboard',
        active: true,
        icon: 'chart-pie',
        to: paths.dashboard.root
      },
      {
        name: 'Users',
        active: true,
        icon: 'users',
        to: paths.dashboard.users
      }
    ]
  }
];

export const EditorRoutes = [
  {
    label: 'Dashboard',
    labelDisable: true,
    children: [
      {
        name: 'Dashboard',
        active: true,
        icon: 'chart-pie',
        to: paths.dashboard.root
      }
    ]
  }
];
