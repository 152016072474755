import is from 'is_js';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import MainLayout from 'layouts/MainLayout';
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CloseButton } from 'components/common/Toast';
import { toast, ToastContainer } from 'react-toastify';
import ErrorLayout from './ErrorLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';

import Dashboard from 'components/dashboard';
import { DeceasedRecordEdit } from 'components/dashboard/DeceasedRecordEdit';
import UsersList from 'components/users';
import UserCreate from 'components/users/UserCreate';
import { UserEdit } from 'components/users/UserEdit';
import AppContext from 'context/Context';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        {/*- ------------- simple ---------------------------  */}
        <Route element={<AuthSimpleLayout />}>
          <Route path="/login" element={<SimpleLogin />} />
          <Route path="/logout" element={<SimpleLogout />} />
          <Route path="/forgot-password" element={<SimpleForgetPassword />} />
          <Route path="/reset-password" element={<SimplePasswordReset />} />
          <Route path="/confirm-mail" element={<SimpleConfirmMail />} />
          <Route path="/lock-screen" element={<SimpleLockScreen />} />
        </Route>

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/edit/:id" element={<DeceasedRecordEdit />} />
          <Route path="/users" element={<UsersList />} />
          <Route path="/users/create" element={<UserCreate />} />
          <Route path="/users/:id" element={<UserEdit />} />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      {/* <SettingsToggle /> */}
      {/* <SettingsPanel /> */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
        theme="colored"
      />
    </>
  );
};

export default Layout;
