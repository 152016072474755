import { useMutation, useQuery } from '@apollo/client';
import { RecordForm } from 'components/dashboard/RecordForm';
import dayjs from 'dayjs';
import {
  DECEASED_RECORD_BY_ID,
  UPDATE_DECEASED_RECORD
} from 'graphql/dashboard';
import { isEmpty } from 'lodash';
import React from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const DeceasedRecordEdit = () => {
  const { id } = useParams();
  const authHeader = useAuthHeader();
  let uploadError = false;

  const { data: deceasedRecordData } = useQuery(DECEASED_RECORD_BY_ID, {
    skip: !id,
    variables: {
      deceasedRecordId: id
    },
    onError: error => toast.error(error.message)
  });

  const deceasedRecord = deceasedRecordData?.deceasedRecord ?? {};

  const [updateDeceasedRecord, { loading }] = useMutation(
    UPDATE_DECEASED_RECORD,
    {
      onError: error => toast.error(error.message),
      refetchQueries: [
        {
          query: DECEASED_RECORD_BY_ID,
          variables: { deceasedRecordId: id }
        }
      ]
    }
  );

  const onSubmit = async data => {
    let formData = {};

    if (data.progress.step1) {
      formData = {
        additionalInformation: data.additionalInformation,
        address: data.address,
        age: data.age,
        phone: data.phone,
        deathDate: dayjs(data.deathDate).format('YYYY-MM-DD'),
        dateOfBirth: dayjs(data.dateOfBirth).format('YYYY-MM-DD'),
        fullName: data.fullName,
        gender: data.gender,
        nextOfKeen: data.nextOfKeen.map(keen => ({
          fullName: keen.fullName,
          relationship: keen.relationship,
          contactNumber: keen.contactNumber
        }))
      };
    }

    if (data.progress.step2) {
      let uploads = [];
      if (data?.deceasedsDocuments) {
        const previousUploads =
          deceasedRecord?.data?.attributes?.deceasedsDocuments?.data?.map(
            doc => doc.id
          );
        const currentUploads = data?.deceasedsDocuments
          ?.filter(obj => 'id' in obj)
          ?.map(obj => obj.id);
        const removedIds = previousUploads?.filter(
          id => !currentUploads.includes(id)
        );
        const newUploads = data?.deceasedsDocuments?.filter(
          obj => !('id' in obj)
        );
        if (!isEmpty(removedIds)) {
          for (const id of removedIds) {
            try {
              await fetch(
                process.env.REACT_APP_API_URL_UPLOAD + `/files/${id}`,
                {
                  method: 'DELETE',
                  headers: {
                    Authorization: authHeader
                  }
                }
              );
              uploads = previousUploads.filter(id => !removedIds.includes(id));
            } catch (error) {
              console.error('Error removing image:', error);
            }
          }
        } else {
          uploads = previousUploads;
        }

        if (!isEmpty(newUploads)) {
          for (const file of newUploads) {
            const uploadFormData = new FormData();
            uploadFormData.append('files', file.file);
            try {
              const response = await fetch(
                process.env.REACT_APP_API_URL_UPLOAD,
                {
                  method: 'POST',
                  body: uploadFormData,
                  headers: {
                    Authorization: authHeader
                  }
                }
              );

              if (!response.ok) {
                uploadError = true;
                console.error('Error uploading image:');
              } else {
                const data = await response.json();
                uploads.push(data?.[0]?.id);
              }
            } catch (error) {
              uploadError = true;
              console.error('Error uploading image:', error);
            }
          }
        }
      }
      formData = {
        ...formData,
        cemeteryPlotInformation: {
          additionalInformation:
            data.cemeteryPlotInformation.additionalInformation,
          cemeteryLocation: data.cemeteryPlotInformation.cemeteryLocation,
          plotNumber: Number(data.cemeteryPlotInformation.plotNumber)
        },
        deceasedsDocuments: uploads
      };
    }

    if (!uploadError) {
      await updateDeceasedRecord({
        variables: {
          updateDeceasedRecordId: id,
          data: formData
        }
      }).then(res => {
        console.log(res);
      });
    } else {
      toast.error('File upload failed, try again');
    }
  };

  return (
    <RecordForm
      onSubmit={onSubmit}
      initialData={deceasedRecord}
      loading={loading}
    />
  );
};
