import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Footer = () => (
  <footer className="footer">
    <Row className="mt-4 mb-3 text-center justify-content-between fs--1">
      <Col sm="auto">
        <p className="mb-0 text-600">
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
          <a href="https://cloudlaunch.io" target="_blank" rel="noreferrer">
            CloudLaunch
          </a>
        </p>
      </Col>
      {/* <Col sm="auto">
        <p className="mb-0 text-600">v{version}</p>
      </Col> */}
    </Row>
  </footer>
);

export default Footer;
