import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import LoadingScreen from 'components/common/LoadingScreen';
import React from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';

const App = () => {
  const authHeader = useAuthHeader();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL_GRAPHQL
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: authHeader ? authHeader : ''
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    connectToDevTools: true,
    cache: new InMemoryCache()
  });

  if (!client) {
    return <LoadingScreen />;
  }

  return (
    <ApolloProvider client={client}>
      <Router er basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </ApolloProvider>
  );
};

export default App;
