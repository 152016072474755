import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from 'components/common/IconButton';
import { BillingInformationTab } from 'components/dashboard/BillingInformationTab';
import { DeceasedRecordTab } from 'components/dashboard/DeceasedRecordTab';
import { PersonalInforMationTab } from 'components/dashboard/PersonalInformationTab';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { Card } from 'react-bootstrap/esm';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

export const RecordForm = ({ onSubmit, initialData, loading }) => {
  const [key, setKey] = useState('personal-information');
  const [editable, setEditable] = useState(false);

  const deceasedRecordSchema = Yup.object().shape({
    progress: Yup.object().shape({
      step1: Yup.boolean().default(false),
      step2: Yup.boolean().default(false)
    }),
    fullName: Yup.string().required('Full name is required'),
    address: Yup.string(),
    deathDate: Yup.date(),
    dateOfBirth: Yup.date(),
    age: Yup.number(),
    phone: Yup.string(),
    gender: Yup.string().required('Gender is required'),
    nextOfKeen: Yup.array().of(
      Yup.object().shape({
        fullName: Yup.string().required('Full name is required'),
        relationship: Yup.string().required('Relationship is required'),
        contactNumber: Yup.number()
      })
    ),
    additionalInformation: Yup.string(),
    cemeteryPlotInformation: Yup.object().shape({
      cemeteryLocation: Yup.string().required('Cemetery Location is required'),
      plotNumber: Yup.number().required('Plot Number is required'),
      additionalInformation: Yup.string()
    }),
    deceasedsDocuments: Yup.array().of(Yup.string())
  });

  const methods = useForm({
    resolver: yupResolver(deceasedRecordSchema),
    defaultValues: {
      progress: {
        step1: false,
        step2: false,
        step3: false
      },
      fullName: '',
      address: '',
      phone: '',
      deathDate: '',
      dateOfBirth: '',
      age: 0,
      gender: '',
      nextOfKeen: [
        { fullName: '', relationship: '', contactNumber: '' },
        { fullName: '', relationship: '', contactNumber: '' }
      ],
      additionalInformation: '',
      cemeteryPlotInformation: {
        cemeteryLocation: '',
        plotNumber: '',
        additionalInformation: ''
      },
      deceasedsDocuments: []
    }
  });

  const { watch, reset, handleSubmit, trigger, setValue } = methods;

  const values = watch();

  const resetInitialValues = useCallback(() => {
    if (!isEmpty(initialData)) {
      setEditable(false);
      const attributes = initialData.data.attributes;
      reset({
        fullName: attributes.fullName,
        address: attributes.address,
        phone: attributes.phone,
        deathDate: new Date(attributes.deathDate),
        dateOfBirth: new Date(attributes.dateOfBirth),
        age: attributes.age,
        gender: attributes.gender,
        additionalInformation: attributes.additionalInformation,
        progress: {
          step1: !!initialData.fullName && !!initialData.gender,
          step2:
            !!initialData.cemeteryPlotInformation?.cemeteryLocation &&
            !!initialData.cemeteryPlotInformation?.plotNumber
        },
        nextOfKeen: attributes?.nextOfKeen.map(nextOfKeen => ({
          ...nextOfKeen,
          fullName: nextOfKeen.fullName,
          relationship: nextOfKeen.relationship,
          contactNumber: nextOfKeen.contactNumber
        })),
        cemeteryPlotInformation: {
          cemeteryLocation:
            attributes?.cemeteryPlotInformation.cemeteryLocation,
          plotNumber: attributes?.cemeteryPlotInformation.plotNumber,
          additionalInformation:
            attributes?.cemeteryPlotInformation.additionalInformation
        },
        deceasedsDocuments: attributes?.deceasedsDocuments.data.map(doc => ({
          id: doc.id,
          file: { name: doc.attributes.name },
          url: doc.attributes.url,
          dataURL: `${process.env.REACT_APP_API_URL}${doc.attributes.url}`
        }))
      });
    } else {
      setEditable(true);
    }
  }, [initialData]);

  useEffect(() => {
    resetInitialValues();
  }, [initialData]);

  const billingRecords = useMemo(() => {
    if (!isEmpty(initialData)) {
      return initialData?.data?.attributes?.billings?.data;
    }
  }, [initialData]);

  const handleValidation = useCallback(async () => {
    let isValid = false;

    if (key === 'personal-information') {
      isValid = await trigger([
        'fullName',
        'gender',
        'nextOfKeen.0.fullName',
        'nextOfKeen.0.relationship'
      ]);
    } else if (key === 'deceased-record') {
      isValid = await trigger([
        'cemeteryPlotInformation.cemeteryLocation',
        'cemeteryPlotInformation.plotNumber'
      ]);
    } else if (key === 'billing') {
      isValid = await trigger(['billing.billingAmount', 'billing.date']);
    } else {
      isValid = true;
    }

    if (isValid) {
      setValue('progress.step1', true);
      return isValid;
    }
  }, [key, values]);

  const progress = useCallback(async () => {
    switch (key) {
      case 'personal-information':
        setValue('progress.step1', true);
        break;
      case 'deceased-record':
        setValue('progress.step1', true);
        setValue('progress.step2', true);
        break;
      default:
        break;
    }
  }, [key]);

  const handleChange = useCallback(async newValue => {
    const isValid = await handleValidation();

    if (isValid) {
      progress();
      setKey(newValue);
    }
  }, []);

  const handleSubmitButton = useCallback(async () => {
    if (await handleValidation()) {
      progress();
      onSubmit(values);
    }
  }, [values]);

  return (
    <Tab.Container activeKey={key} onSelect={k => handleChange(k)}>
      <Row className="gap-3 gap-md-0">
        <Col md={3}>
          <Card>
            <Card.Body>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="personal-information">
                    Personal Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="deceased-record">
                    Deceased Record
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="billing">Billing</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Body>
          </Card>
          {!isEmpty(initialData) && (
            <IconButton
              className="mb-1 me-2 mt-3 w-100"
              variant={editable ? 'outline-primary' : 'primary'}
              icon="edit"
              transform="shrink-3"
              onClick={() => {
                if (editable) {
                  resetInitialValues();
                  setEditable(!editable);
                } else {
                  setEditable(!editable);
                }
              }}
            >
              {editable ? 'Cancel Edit' : 'Edit'}
            </IconButton>
          )}
        </Col>
        <Col md={9}>
          <Tab.Content>
            <FormProvider {...methods} onSubmit={handleSubmit(onSubmit)}>
              <Tab.Pane eventKey="personal-information">
                <PersonalInforMationTab
                  handleChange={handleChange}
                  editable={editable}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="deceased-record">
                <DeceasedRecordTab
                  handleSubmitButton={handleSubmitButton}
                  loading={loading}
                  editable={editable}
                />
              </Tab.Pane>
            </FormProvider>
            <Tab.Pane eventKey="billing">
              <BillingInformationTab
                billingRecords={billingRecords}
                editable={editable}
              />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

RecordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.any,
  loading: PropTypes.bool
};
