import { gql } from '@apollo/client';

export const DECEASED_RECORD_COUNT = gql`
  query DeceasedRecordCount(
    $lastMonthFilters: DeceasedRecordFiltersInput
    $lastWeekFilters: DeceasedRecordFiltersInput
  ) {
    total: deceasedRecords {
      data {
        id
      }
    }
    lastWeek: deceasedRecords(filters: $lastWeekFilters) {
      data {
        id
      }
    }
    lastMonth: deceasedRecords(filters: $lastMonthFilters) {
      data {
        id
      }
    }
  }
`;

export const DECEASED_RECORDS = gql`
  query DeceasedRecords {
    deceasedRecords {
      data {
        id
        attributes {
          additionalInformation
          address
          age
          billings {
            data {
              id
              attributes {
                billingAmount
                comments
                date
                documents {
                  data {
                    id
                    attributes {
                      name
                      url
                    }
                  }
                }
              }
            }
          }
          cemeteryPlotInformation {
            additionalInformation
            cemeteryLocation
            id
            plotNumber
          }
          createdAt
          deathDate
          dateOfBirth
          phone
          deceasedsDocuments {
            data {
              attributes {
                name
                url
              }
              id
            }
          }
          fullName
          gender
          nextOfKeen {
            contactNumber
            fullName
            id
            relationship
          }
        }
      }
    }
  }
`;

export const DECEASED_RECORD_BY_ID = gql`
  query DeceasedRecordById($deceasedRecordId: ID) {
    deceasedRecord(id: $deceasedRecordId) {
      data {
        attributes {
          fullName
          address
          deathDate
          dateOfBirth
          phone
          age
          gender
          nextOfKeen {
            contactNumber
            fullName
            id
            relationship
          }
          additionalInformation
          cemeteryPlotInformation {
            id
            cemeteryLocation
            plotNumber
            additionalInformation
          }
          deceasedsDocuments {
            data {
              id
              attributes {
                name
                url
              }
            }
          }
          billings {
            data {
              id
              attributes {
                billingAmount
                date
                documents {
                  data {
                    id
                    attributes {
                      name
                      url
                    }
                  }
                }
                comments
                createdAt
                updatedAt
                publishedAt
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const CREATE_DECEASED_RECORD = gql`
  mutation CreateDeceasedRecord($data: DeceasedRecordInput!) {
    createDeceasedRecord(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_DECEASED_RECORD = gql`
  mutation UpdateDeceasedRecord(
    $updateDeceasedRecordId: ID!
    $data: DeceasedRecordInput!
  ) {
    updateDeceasedRecord(id: $updateDeceasedRecordId, data: $data) {
      data {
        id
      }
    }
  }
`;

export const CREATE_BILLING = gql`
  mutation CreateBilling($data: BillingInput!) {
    createBilling(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_DECEASED_RECORD = gql`
  mutation DeleteDeceasedRecord($deleteDeceasedRecordId: ID!) {
    deleteDeceasedRecord(id: $deleteDeceasedRecordId) {
      data {
        id
      }
    }
  }
`;

export const DELETE_BILLING = gql`
  mutation DeleteBilling($deleteBillingId: ID!) {
    deleteBilling(id: $deleteBillingId) {
      data {
        id
      }
    }
  }
`;

export const DELETE_UPLOADED_FILE = gql`
  mutation DeleteUploadedFile($deleteUploadFileId: ID!) {
    deleteUploadFile(id: $deleteUploadFileId) {
      data {
        id
      }
    }
  }
`;
