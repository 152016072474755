import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query Users {
    usersPermissionsUsers {
      data {
        id
        attributes {
          username
          email
          blocked
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query UsersPermissionsUser($usersPermissionsUserId: ID) {
    usersPermissionsUser(id: $usersPermissionsUserId) {
      data {
        id
        attributes {
          username
          email
          provider
          confirmed
          blocked
          role {
            data {
              id
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUsersPermissionsUser($data: UsersPermissionsUserInput!) {
    createUsersPermissionsUser(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $updateUsersPermissionsUserId: ID!
    $data: UsersPermissionsUserInput!
  ) {
    updateUsersPermissionsUser(id: $updateUsersPermissionsUserId, data: $data) {
      data {
        id
      }
    }
  }
`;
