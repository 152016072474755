import { useMutation } from '@apollo/client';
import { UserForm } from 'components/users/UserForm';
import { CREATE_USER } from 'graphql/users';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UserCreate = () => {
  const navigate = useNavigate();

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted: data => {
      toast.success('User created successfully!');
      navigate(`/users/${data.createUsersPermissionsUser.data.id}`);
    },
    onError: error => toast.error(error.message, { variant: 'error' }),
    refetchQueries: ['Users']
  });

  const onSubmit = async data => {
    await createUser({
      variables: {
        data: {
          username: data.username,
          role: 3,
          password: data.password,
          email: data.email,
          confirmed: true,
          blocked: false
        }
      }
    });
  };

  return <UserForm onSubmit={onSubmit} loading={loading} initialData={{}} />;
};

export default UserCreate;
