import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  useAccordionButton
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FaChevronDown } from 'react-icons/fa6';

export const PersonalInforMationTab = ({ handleChange, editable }) => {
  const {
    control,
    setValue,
    register,
    formState: { errors }
  } = useFormContext();

  const { fields: nextOfKeenFields } = useFieldArray({
    name: 'nextOfKeen',
    control,
    rules: { required: 'Please append at least 1 item' }
  });

  return (
    <Flex className="gap-3" direction="column">
      <Card>
        <Card.Body>
          <h5 className="gap-2 d-flex align-items-center">
            Deceased Personal Information
          </h5>
          <Divider />

          <Row>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>
                Full Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.fullName}
                disabled={!editable}
                {...register('fullName')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fullName && errors.fullName.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={6} className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                disabled={!editable}
                {...register('address')}
              />
            </Form.Group>
            <Form.Group as={Col} xs={6} className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="string"
                disabled={!editable}
                {...register('phone')}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={6}
              className="gap-1 mb-3 d-flex flex-column"
            >
              <Form.Label>Date of Birth</Form.Label>
              <Controller
                control={control}
                name="dateOfBirth"
                disabled={!editable}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    popperPlacement="bottom-start"
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    selected={field.value}
                    onChange={date => {
                      setValue('age', dayjs().diff(dayjs(date), 'years'));
                      field.onChange(date);
                    }}
                    formatWeekDay={day => day.slice(0, 3)}
                    className="form-control"
                    placeholderText="Select Date"
                    showYearDropdown
                    showMonthDropdown
                  />
                )}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={6}
              className="gap-1 mb-3 d-flex flex-column"
            >
              <Form.Label>Date of Death</Form.Label>
              <Controller
                control={control}
                name="deathDate"
                disabled={!editable}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    popperPlacement="bottom-start"
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    selected={field.value}
                    onChange={date => {
                      setValue('age', dayjs().diff(dayjs(date), 'years'));
                      field.onChange(date);
                    }}
                    formatWeekDay={day => day.slice(0, 3)}
                    className="form-control"
                    placeholderText="Select Date"
                    showYearDropdown
                    showMonthDropdown
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Age</Form.Label>
              <Form.Control type="number" disabled {...register('age')} />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>
                Gender <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                isInvalid={!!errors.gender}
                disabled={!editable}
                {...register('gender')}
              >
                <option>Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.gender && errors.gender.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      <Accordion
        alwaysOpen
        defaultActiveKey={['0', '1', '2']}
        className="gap-3 d-flex flex-column"
      >
        {nextOfKeenFields.map((_, index) => (
          <Card key={index}>
            <Card.Body>
              <CustomToggle eventKey={String(index)}>
                <Flex alignItems="center" justifyContent="between">
                  <h5 className="gap-2 d-flex align-items-center">
                    Next of Keen {index + 1}
                  </h5>
                  <FaChevronDown />
                </Flex>
              </CustomToggle>
              <Accordion.Collapse eventKey={String(index)}>
                <>
                  <Divider />
                  <Row>
                    <Form.Group as={Col} xs={12} className="mb-3">
                      <Form.Label>
                        Full Name
                        {index === 0 && <span className="text-danger">*</span>}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={!editable}
                        isInvalid={!!errors?.nextOfKeen?.[index]?.fullName}
                        {...register(`nextOfKeen.${index}.fullName`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.nextOfKeen?.[index]?.fullName &&
                          errors?.nextOfKeen?.[index]?.fullName.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-3">
                      <Form.Label>
                        Relationship
                        {index === 0 && <span className="text-danger">*</span>}
                      </Form.Label>
                      <Form.Select
                        disabled={!editable}
                        isInvalid={!!errors?.nextOfKeen?.[index]?.relationship}
                        {...register(`nextOfKeen.${index}.relationship`)}
                      >
                        <option>Select</option>
                        <option value="1">Father</option>
                        <option value="2">Mother</option>
                        <option value="3">Brother</option>
                        <option value="4">Sister</option>
                        <option value="5">Other</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors?.nextOfKeen?.[index]?.relationship &&
                          errors?.nextOfKeen?.[index]?.relationship.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} className="mb-3">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="number"
                        disabled={!editable}
                        {...register(`nextOfKeen.${index}.contactNumber`)}
                      />
                    </Form.Group>
                  </Row>
                </>
              </Accordion.Collapse>
            </Card.Body>
          </Card>
        ))}

        <Card>
          <Card.Body>
            <CustomToggle eventKey="2">
              <Flex alignItems="center" justifyContent="between">
                <h5 className="gap-2 d-flex align-items-center">
                  Additional Information
                </h5>
                <FaChevronDown />
              </Flex>
            </CustomToggle>
            <Accordion.Collapse eventKey="2">
              <>
                <Divider />
                <Row>
                  <Form.Group as={Col} xs={12} className="mb-3">
                    <Form.Label>Additional Information</Form.Label>
                    <Form.Control
                      as="textarea"
                      type="text"
                      disabled={!editable}
                      {...register('additionalInformation')}
                    />
                  </Form.Group>
                </Row>
              </>
            </Accordion.Collapse>
          </Card.Body>
        </Card>
      </Accordion>

      <Button
        type="button"
        variant="primary"
        onClick={() => handleChange('deceased-record')}
      >
        Next
      </Button>
    </Flex>
  );
};

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);
  return <div onClick={decoratedOnClick}>{children}</div>;
}

CustomToggle.propTypes = {
  children: PropTypes.any,
  eventKey: PropTypes.string
};

PersonalInforMationTab.propTypes = {
  handleChange: PropTypes.func.isRequired,
  editable: PropTypes.bool
};
