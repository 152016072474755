import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

export const UserForm = ({ onSubmit, initialData, loading }) => {
  const navigate = useNavigate();

  const userFormSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().required('Email is required'),
    password: Yup.string(),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password')],
      'Passwords must match'
    ),
    role: Yup.number(),
    blocked: Yup.boolean()
  });

  const methods = useForm({
    resolver: yupResolver(userFormSchema),
    defaultValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: 3,
      blocked: true
    }
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = methods;

  useEffect(() => {
    if (!isEmpty(initialData)) {
      reset({
        username: initialData.attributes.username,
        email: initialData.attributes.email,
        blocked: !initialData.attributes.blocked
      });
    }
  }, [initialData]);

  return (
    <FormProvider {...methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Card.Body>
          <Row>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>
                Username <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.username}
                {...register('username')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username && errors.username.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>
                Email <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                isInvalid={!!errors.email}
                {...register('email')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>
                {!isEmpty(initialData) ? 'New Password' : 'Password'}
              </Form.Label>
              <Form.Control
                type="password"
                isInvalid={!!errors.password}
                {...register('password')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password && errors.password.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.confirmPassword}
                {...register('confirmPassword')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword && errors.confirmPassword.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>Is Active?</Form.Label>
              <Form.Check type="switch" {...register('blocked')} />
            </Form.Group>
          </Row>

          <Flex className="gap-3" alignItems="center">
            <Button
              disabled={loading}
              type="submit"
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>

            <Button onClick={() => navigate(-1)} type="button" variant="ghost">
              Go Back
            </Button>
          </Flex>
        </Card.Body>
      </Card>
    </FormProvider>
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.any,
  loading: PropTypes.bool
};
