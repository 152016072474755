import { useMutation, useQuery } from '@apollo/client';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { DELETE_DECEASED_RECORD } from 'graphql/dashboard';
import { GET_USERS } from 'graphql/users';
import React from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UsersList = () => {
  const navigate = useNavigate();

  const { data: usersData } = useQuery(GET_USERS, {
    onError: error => toast.error(error.message, { variant: 'error' })
  });

  const users = usersData
    ? usersData?.usersPermissionsUsers?.data.map(user => ({
        id: user.id,
        ...user.attributes
      }))
    : [];

  return (
    <Card>
      <Card.Body>
        <Flex justifyContent="between" alignItems="center" className="mb-3">
          <h4 className="mb-0">Users</h4>
          <Button onClick={() => navigate('/users/create')}>Add User</Button>
        </Flex>
        <AdvanceTableWrapper
          columns={columns}
          data={users}
          sortable
          pagination
          perPage={5}
          // selection
          // selectionColumnWidth={30}
        >
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTablePagination />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

const columns = [
  {
    accessor: 'id',
    Header: 'ID'
  },
  {
    accessor: 'username',
    Header: 'User Name'
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    accessor: 'blocked',
    Header: 'Status',
    Cell: rowData => {
      const { blocked } = rowData.row.original;
      return blocked ? 'Inactive' : 'Active';
    }
  },
  {
    accessor: 'action',
    Header: 'Actions',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const navigate = useNavigate();
      const { id } = rowData.row.original;
      const [modalShow, setModalShow] = React.useState(false);

      const [deleteRecord, { loading }] = useMutation(DELETE_DECEASED_RECORD, {
        onError: error => toast.error(error.message)
      });

      return (
        <>
          <IconButton
            className="mb-1 me-2"
            variant="primary"
            icon="edit"
            transform="shrink-3"
            onClick={() => navigate(`/users/${id}`)}
          ></IconButton>
          <IconButton
            className="mb-1 me-2"
            variant="danger"
            icon="trash"
            transform="shrink-3"
            loading={loading}
            onClick={() => setModalShow(true)}
          ></IconButton>
          <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delete Record
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this record?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={async () => {
                  await deleteRecord({
                    variables: { deleteDeceasedRecordId: id }
                  });
                  setModalShow(false);
                }}
                variant="danger"
              >
                Yes
              </Button>
              <Button onClick={() => setModalShow(false)} variant="primary">
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

export default UsersList;
