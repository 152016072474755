import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';

export const DeceasedRecordTab = ({
  handleSubmitButton,
  loading,
  editable
}) => {
  const { register, setValue, getValues } = useFormContext();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!isEmpty(getValues('deceasedsDocuments'))) {
      setFiles(getValues('deceasedsDocuments'));
    }
  }, [getValues('deceasedsDocuments')]);

  const onChange = async imageList => {
    setFiles(imageList);
    setValue('deceasedsDocuments', imageList);
  };

  return (
    <Flex className="gap-3" direction="column">
      <Card>
        <Card.Body>
          <h5 className="gap-2 d-flex align-items-center">
            Cemetery Plot Information
          </h5>
          <Divider />

          <Row>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Cemetery Location</Form.Label>
              <Form.Select
                disabled={!editable}
                {...register('cemeteryPlotInformation.cemeteryLocation')}
              >
                <option>Select</option>
                <option value="Kikoani">Kikoani</option>
                <option value="Kiziwi">Kiziwi</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={6} className="mb-3">
              <Form.Label>Plot Number</Form.Label>
              <Form.Control
                type="number"
                disabled={!editable}
                {...register('cemeteryPlotInformation.plotNumber')}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>Additional Information</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                disabled={!editable}
                {...register('cemeteryPlotInformation.additionalInformation')}
              />
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h5 className="gap-2 d-flex align-items-center">
            Deceased's Documents
          </h5>
          <Divider />

          <Row>
            <Form.Group as={Col} md={12}>
              {editable && <Form.Label>Upload Documents</Form.Label>}

              <ImageUploading value={files} onChange={onChange} multiple>
                {({
                  imageList,
                  onImageUpload,
                  // onImageRemoveAll,
                  // onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    {editable && (
                      <Button
                        style={{ backgroundColor: 'transparent' }}
                        onClick={onImageUpload}
                        disabled={!editable}
                        className="py-6 dropzone-area w-100"
                        {...dragProps}
                      >
                        <Flex justifyContent="center">
                          <img
                            src={cloudUpload}
                            alt=""
                            width={25}
                            className="me-2"
                          />
                          <p
                            className="mb-0 fs-0"
                            style={
                              isDragging ? { color: 'red' } : { color: '#999' }
                            }
                          >
                            Drop your images here
                          </p>
                        </Flex>
                      </Button>
                    )}
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        className="pb-2 my-2 image-item d-flex align-items-center justify-content-between border-bottom"
                      >
                        <img src={image['dataURL']} alt="" width="50" />
                        <span className="me-auto ms-3">
                          {image?.file?.name}
                        </span>
                        {editable && (
                          <div className="image-item__btn-wrapper">
                            <Button
                              variant="falcon-danger"
                              onClick={() => onImageRemove(index)}
                              size="sm"
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      <Button variant="primary" onClick={handleSubmitButton} disabled={loading}>
        Save
      </Button>
    </Flex>
  );
};

DeceasedRecordTab.propTypes = {
  handleSubmitButton: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  editable: PropTypes.bool
};
