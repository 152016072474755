import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { APP_URL } from 'config';
import dayjs from 'dayjs';
import { LOGIN } from 'graphql/auth';
import {
  CREATE_BILLING,
  DECEASED_RECORD_BY_ID,
  DELETE_BILLING,
  DELETE_UPLOADED_FILE,
  UPDATE_DECEASED_RECORD
} from 'graphql/dashboard';
import { IsAdmin } from 'helpers/isAdmin';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export const BillingInformationTab = ({ billingRecords, editable }) => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const authHeader = useAuthHeader();
  let uploadError = false;

  const [createBilling, { loading: createBillingLoading }] = useMutation(
    CREATE_BILLING,
    {
      onError: error => toast.error(error.message)
    }
  );

  const [updateDeceasedRecord, { loading: updateDeceasedRecordLoading }] =
    useMutation(UPDATE_DECEASED_RECORD, {
      onError: error => toast.error(error.message),
      refetchQueries: [
        {
          query: DECEASED_RECORD_BY_ID,
          variables: { deceasedRecordId: id }
        }
      ]
    });

  const billingSchema = Yup.object().shape({
    billingAmount: Yup.number().required('Billing Amount is required'),
    date: Yup.date().required('Date is required'),
    comments: Yup.string(),
    documents: Yup.array()
  });

  const methods = useForm({
    resolver: yupResolver(billingSchema),
    defaultValues: {
      billingAmount: '',
      date: '',
      comments: '',
      documents: []
    }
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors }
  } = methods;

  const onChange = async imageList => {
    setFiles(imageList);
    setValue('documents', imageList);
  };

  const onSubmit = async data => {
    let uploads = [];
    if (data?.documents) {
      for (const file of data.documents) {
        const formData = new FormData();
        formData.append('files', file.file);
        try {
          const response = await fetch(process.env.REACT_APP_API_URL_UPLOAD, {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: authHeader
            }
          });

          if (!response.ok) {
            uploadError = true;
            console.error('Error uploading image:');
          } else {
            const data = await response.json();
            uploads.push(data?.[0]?.id);
          }
        } catch (error) {
          uploadError = true;
          console.error('Error uploading image:', error);
        }
      }
    }
    if (!uploadError) {
      await createBilling({
        variables: {
          data: {
            publishedAt: dayjs(),
            documents: uploads,
            date: dayjs(data.date).format('YYYY-MM-DD'),
            comments: data.comments,
            billingAmount: data.billingAmount
          }
        }
      }).then(async res => {
        await updateDeceasedRecord({
          variables: {
            updateDeceasedRecordId: id,
            data: {
              billings: [
                ...billingRecords.map(record => record.id),
                res?.data?.createBilling?.data?.id
              ]
            }
          }
        });
        reset();
      });
    } else {
      toast.error('Error uploading image, try again');
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Flex className="gap-3" direction="column">
        {editable && (
          <Card>
            <Card.Body>
              <h5 className="gap-2 d-flex align-items-center">
                Billing Information
              </h5>
              <Divider />

              <Row>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Label>Billing Amount</Form.Label>
                  <Form.Control
                    type="number"
                    disabled={!editable}
                    isInvalid={!!errors.billingAmount}
                    {...register('billingAmount')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.billingAmount && errors.billingAmount.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="gap-1 mb-3 d-flex flex-column"
                >
                  <Form.Label>Date</Form.Label>
                  <Controller
                    control={control}
                    name="date"
                    errors={errors}
                    isInvalid={!!errors.date}
                    disabled={!editable}
                    rules={{
                      required: 'Date of birth is required'
                    }}
                    render={({ field }) => (
                      <>
                        <DatePicker
                          {...field}
                          popperPlacement="bottom-start"
                          dateFormat="dd-MM-yyyy"
                          selected={field.value}
                          onChange={date => field.onChange(date)}
                          formatWeekDay={day => day.slice(0, 3)}
                          className="form-control"
                          placeholderText="Select Date"
                          showYearDropdown
                          showMonthDropdown
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.date && errors.date.message}
                        </Form.Control.Feedback>
                      </>
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label>Upload Documents</Form.Label>

                  <ImageUploading value={files} onChange={onChange}>
                    {({
                      // imageList,
                      onImageUpload,
                      // onImageRemoveAll,
                      // onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <Button
                          style={{ backgroundColor: 'transparent' }}
                          onClick={onImageUpload}
                          className="py-6 dropzone-area w-100"
                          {...dragProps}
                        >
                          <Flex justifyContent="center">
                            <img
                              src={cloudUpload}
                              alt=""
                              width={25}
                              className="me-2"
                            />
                            <p
                              className="mb-0 fs-0"
                              style={
                                isDragging
                                  ? { color: 'red' }
                                  : { color: '#999' }
                              }
                            >
                              Drop your images here
                            </p>
                          </Flex>
                        </Button>
                        {files.map((image, index) => (
                          <div
                            key={index}
                            className="pb-2 my-2 image-item d-flex align-items-center justify-content-between border-bottom"
                          >
                            <img src={image['dataURL']} alt="" width="50" />
                            <span className="me-auto ms-3">
                              {image?.file?.name}
                            </span>
                            <div className="image-item__btn-wrapper">
                              <Button
                                variant="falcon-danger"
                                onClick={() => onImageRemove(index)}
                                size="sm"
                              >
                                Remove
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </Form.Group>
                <Form.Group as={Col} xs={12} className="mb-3">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={!editable}
                    {...register('comments')}
                  />
                </Form.Group>
              </Row>
              <Button
                type="submit"
                variant="primary"
                className="w-100"
                disabled={createBillingLoading || updateDeceasedRecordLoading}
              >
                Save
              </Button>
            </Card.Body>
          </Card>
        )}

        <Card>
          <Card.Body>
            <h5 className="mb-3">Billing Table Record</h5>
            {!isEmpty(billingRecords) ? (
              <AdvanceTableWrapper
                columns={columns(editable)}
                data={
                  billingRecords &&
                  billingRecords?.map(billingRecord => ({
                    id: billingRecord.id,
                    file: billingRecord.attributes.documents.data?.map(img => ({
                      id: img.id,
                      url: img.attributes.url
                    })),
                    date: billingRecord.attributes.date,
                    billingAmount: '$' + billingRecord.attributes.billingAmount,
                    comments: billingRecord.attributes.comments,
                    editable
                  }))
                }
                sortable
                pagination
                perPage={5}
                selection
                selectionColumnWidth={30}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </AdvanceTableWrapper>
            ) : (
              <p className="mb-0">No records</p>
            )}
          </Card.Body>
        </Card>
      </Flex>
    </Form>
  );
};

const columns = editable => [
  {
    accessor: 'file',
    Header: 'File',
    Cell: rowData => {
      const { file } = rowData.row.original;
      return !isEmpty(file) ? (
        <a href={APP_URL + file?.[0]?.url} target="_blank" rel="noreferrer">
          <img src={APP_URL + file?.[0]?.url} width={25} />
        </a>
      ) : (
        <>No File</>
      );
    }
  },
  {
    accessor: 'date',
    Header: 'Date'
  },
  {
    accessor: 'comments',
    Header: 'Comments'
  },
  {
    accessor: 'billingAmount',
    Header: 'Amount'
  },
  {
    accessor: 'actions',
    Header: '',
    Cell: rowData => {
      const { id: deceasedRecordId } = useParams();
      const { id, file } = rowData.row.original;
      const [modalShow, setModalShow] = React.useState(false);
      const auth = useAuthUser();

      const [deleteRecord, { loading: deleteBillingLoading }] = useMutation(
        DELETE_BILLING,
        {
          onCompleted: () => toast.success('Record deleted successfully'),
          onError: error => toast.error(error.message),
          refetchQueries: [
            {
              query: DECEASED_RECORD_BY_ID,
              variables: { deceasedRecordId: deceasedRecordId }
            }
          ]
        }
      );

      const [deleteUploadedFile] = useMutation(DELETE_UPLOADED_FILE, {
        onError: error => toast.error(error.message)
      });

      const [login, { loading: loginLoading }] = useMutation(LOGIN, {
        onCompleted: async data => {
          if (data.login.user) {
            await deleteUploadedFile({
              variables: { deleteUploadFileId: file?.[0]?.id }
            });
            await deleteRecord({
              variables: {
                deleteBillingId: id
              }
            });
            reset();
            setModalShow(false);
          } else {
            console.error('Something went wrong');
          }
        },
        onError: error => toast.error(error.message)
      });

      const deleteRecordSchema = Yup.object().shape({
        password: Yup.string().required('Full name is required')
      });

      const methods = useForm({
        resolver: yupResolver(deleteRecordSchema),
        defaultValues: {
          password: ''
        }
      });

      const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = methods;

      const onSubmit = async data => {
        await login({
          variables: {
            input: { identifier: auth.email, password: data.password }
          }
        });
      };

      return (
        <>
          {IsAdmin() && editable && (
            <IconButton
              className="mb-1 me-2"
              variant="danger"
              icon="trash"
              transform="shrink-3"
              disabled={loginLoading || deleteBillingLoading}
              onClick={() => setModalShow(true)}
            ></IconButton>
          )}
          <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delete Billing
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this record?</p>
              <FormProvider {...methods} onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Col} xs={12} className="mb-3">
                  <Form.Label>
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    isInvalid={!!errors.password}
                    {...register('password')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password && errors.password.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </FormProvider>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleSubmit(onSubmit)} variant="danger">
                Yes
              </Button>
              <Button onClick={() => setModalShow(false)} variant="primary">
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

BillingInformationTab.propTypes = {
  billingRecords: PropTypes.array,
  editable: PropTypes.bool
};
