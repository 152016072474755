import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import DeceasedRecordHeader from 'components/dashboard/DeceasedRecordHeader';
import dayjs, { duration } from 'dayjs';
import { LOGIN } from 'graphql/auth';
import {
  DECEASED_RECORD_COUNT,
  DELETE_BILLING,
  DELETE_DECEASED_RECORD,
  DELETE_UPLOADED_FILE
} from 'graphql/dashboard';
import { IsAdmin } from 'helpers/isAdmin';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FaCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

dayjs.extend(duration);

const DeceasedRecord = ({ setKey, records }) => {
  const isAuthenticated = useIsAuthenticated();
  const lastMonthDate = useMemo(() => dayjs().subtract(30, 'day'), []);
  const lastWeekDate = useMemo(() => dayjs().subtract(7, 'day'), []);

  const [total, setTotal] = useState(0);
  const [lastWeek, setLastWeek] = useState(0);
  const [lastMonth, setLastMonth] = useState(0);

  useQuery(DECEASED_RECORD_COUNT, {
    skip: !isAuthenticated,
    variables: {
      lastMonthFilters: {
        publishedAt: { gte: lastMonthDate }
      },
      lastWeekFilters: {
        publishedAt: { gte: lastWeekDate }
      }
    },
    onCompleted: data => {
      setTotal(data.total.data.length ?? 0);
      setLastWeek(data.lastWeek.data.length ?? 0);
      setLastMonth(data.lastMonth.data.length ?? 0);
    },
    onError: error => toast.error(error.message)
  });

  return (
    <Flex direction="column" className="gap-3">
      <Card>
        <Card.Body>
          <Row className="gap-3">
            <Col xs={12} md={8}>
              <Row justify="center" className="gap-3">
                <Col xs={12} md={3}>
                  <h6 className="gap-2 d-flex align-items-center">
                    <FaCircle className="text-primary" />
                    Total Record
                  </h6>
                  <h3 className="mb-0">{total}</h3>
                </Col>
                <Col xs={12} md={3}>
                  <h6 className="gap-2 d-flex align-items-center">
                    <FaCircle className="text-primary" />
                    Last Week Record
                  </h6>
                  <h3 className="mb-0">{lastWeek}</h3>
                </Col>
                <Col xs={12} md={3}>
                  <h6 className="gap-2 d-flex align-items-center">
                    <FaCircle className="text-primary" />
                    Last Month Record
                  </h6>
                  <h3 className="mb-0">{lastMonth}</h3>
                </Col>
              </Row>
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
              <IconButton
                className="mb-1 w-100 w-md-auto me-2"
                variant="primary"
                icon="plus"
                transform="shrink-3"
                onClick={() => setKey('add-record')}
              >
                Add Record
              </IconButton>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            columns={columns}
            data={records}
            sortable
            pagination
            perPage={5}
            // selection
            // selectionColumnWidth={30}
          >
            <DeceasedRecordHeader table />
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 mb-0'
              }}
            />
            <div className="mt-3">
              <AdvanceTablePagination />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </Flex>
  );
};

const columns = [
  {
    accessor: 'id',
    Header: 'ID'
  },
  {
    accessor: 'name',
    Header: 'Name',
    Cell: rowData => {
      const navigate = useNavigate();
      const { id, name } = rowData.row.original;
      return (
        <span
          className="font-bold cursor-pointer text-decoration-underline"
          onClick={() => navigate(`/edit/${id}`)}
          style={{ color: 'blue' }}
        >
          {name}
        </span>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Phone'
  },
  {
    accessor: 'dateOfBirth',
    Header: 'Date of Birth'
  },
  {
    accessor: 'deathDate',
    Header: 'Death Date'
  },
  {
    accessor: 'gender',
    Header: 'Gender'
  },
  {
    accessor: 'address',
    Header: 'Address'
  },
  {
    accessor: 'plotNumber',
    Header: 'Plot Number',
    Cell: rowData => {
      const { plotNumber } = rowData.row.original;
      return (
        <span className="text-black fs--2 badge badge-pill bg-light">
          {plotNumber}
        </span>
      );
    }
  },
  {
    accessor: 'action',
    Header: 'Actions',
    Cell: rowData => {
      const navigate = useNavigate();
      const { id, billings, files } = rowData.row.original;
      const [modalShow, setModalShow] = React.useState(false);
      const auth = useAuthUser();

      const [deleteRecord, { loading: deleteRecordLoading }] = useMutation(
        DELETE_DECEASED_RECORD,
        {
          onCompleted: () => toast.success('Record deleted successfully'),
          onError: error => toast.error(error.message),
          refetchQueries: ['DeceasedRecords']
        }
      );

      const [deleteUploadedFile] = useMutation(DELETE_UPLOADED_FILE, {
        onError: error => toast.error(error.message)
      });

      const [deleteBilling] = useMutation(DELETE_BILLING, {
        onError: error => toast.error(error.message)
      });

      const [login, { loading: loginLoading }] = useMutation(LOGIN, {
        onCompleted: async data => {
          if (data.login.user) {
            await files.forEach(
              async file =>
                await deleteUploadedFile({
                  variables: { deleteUploadFileId: file.id }
                })
            );
            await billings.forEach(
              async billing =>
                await deleteBilling({
                  variables: { deleteBillingId: billing.id }
                })
            );
            await deleteRecord({
              variables: { deleteDeceasedRecordId: id }
            });
            reset();
            setModalShow(false);
          } else {
            console.error('Something went wrong');
          }
        },
        onError: error => toast.error(error.message)
      });

      const deleteRecordSchema = Yup.object().shape({
        password: Yup.string().required('Full name is required')
      });

      const methods = useForm({
        resolver: yupResolver(deleteRecordSchema),
        defaultValues: {
          password: ''
        }
      });

      const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = methods;

      const onSubmit = async data => {
        await login({
          variables: {
            input: { identifier: auth.email, password: data.password }
          }
        });
      };

      return (
        <>
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item onClick={() => navigate(`/edit/${id}`)}>
                View
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate(`/edit/${id}`)}>
                Edit
              </Dropdown.Item>
              {IsAdmin() && (
                <Dropdown.Item
                  className="text-danger"
                  disabled={loginLoading || deleteRecordLoading}
                  onClick={() => setModalShow(true)}
                >
                  Delete
                </Dropdown.Item>
              )}
            </div>
          </CardDropdown>
          <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delete Record
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this record?</p>
              <FormProvider {...methods} onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Col} xs={12} className="mb-3">
                  <Form.Label>
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    isInvalid={!!errors.password}
                    {...register('password')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password && errors.password.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </FormProvider>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleSubmit(onSubmit)} variant="danger">
                Yes
              </Button>
              <Button onClick={() => setModalShow(false)} variant="primary">
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

DeceasedRecord.propTypes = {
  setKey: PropTypes.func.isRequired,
  records: PropTypes.any
};

export default DeceasedRecord;
