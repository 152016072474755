import Avatar from 'components/common/Avatar';
import React from 'react';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const ProfileDropdown = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link d-flex"
      >
        <Avatar
          icon="user-circle"
          mediaClass="align-items-center justify-content-center fs-4 bg-white text-primary"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end">
        <div className="py-2 bg-white rounded-2 dark__bg-1000">
          <Dropdown.Item
            onClick={async () => {
              await signOut();
              navigate('/login');
            }}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
