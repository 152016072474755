import React from 'react';
import { Spinner } from 'react-bootstrap';
import Flex from './Flex';

function LoadingScreen() {
  return (
    <>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="min-vh-100 min-vw-100"
      >
        <Spinner animation="border" className="mt-3" variant="primary" />
      </Flex>
    </>
  );
}

export default LoadingScreen;
